// npm
import { graphql } from 'gatsby';
import React from 'react';
import { PortableText } from '@portabletext/react';

// components
import SEO from '@components/shared/SEO';
import HeroHeading from '@components/molecules/HeroHeading';
import ImageContainer from '@components/atoms/images/ImageContainer';
import Button from '@components/atoms/buttons/Button';
import LayoutContainer from '@components/shared/layout/LayoutContainer';
import { StyledSection } from './styles';
import ContentBlock from '@components/molecules/ContentBlock';
import { PastGrantWinnerTemplateProps } from './types';

const PastGrantWinnerTemplate: React.FC<PastGrantWinnerTemplateProps> = ({
  data,
}) => {
  const {
    pastGrantWinnerTitle,
    pastGrantWinnerImg,
    publishDate,
    pastGrantWinnerTextBlock,
  } = data.pastGrantWinnerBlog;

  return (
    <>
      <SEO title={pastGrantWinnerTitle} />
      <HeroHeading>
        <h1>{pastGrantWinnerTitle}</h1>
        {pastGrantWinnerImg ? (
          <ImageContainer
            image={pastGrantWinnerImg?.asset?.gatsbyImageData ?? null}
            type="dynamic"
            alt={pastGrantWinnerImg?.alt ? pastGrantWinnerImg.alt : ``}
          />
        ) : null}
        <p>{publishDate}</p>
        <div
          style={{
            display: `flex`,
            gap: `var(--size-dual-nudge)`,
          }}
        >
          <Button
            theme="outline"
            actionType="internal"
            link="/past-grant-winners"
          >
            All Past Grant Winners
          </Button>
        </div>
      </HeroHeading>
      <LayoutContainer hasSectionGaps>
        <StyledSection>
          <ContentBlock>
            <PortableText value={pastGrantWinnerTextBlock} />
          </ContentBlock>
        </StyledSection>
      </LayoutContainer>
    </>
  );
};

export default PastGrantWinnerTemplate;

export const query = graphql`
  query ($slug: String!) {
    pastGrantWinnerBlog: sanityPastGrantWinnerBlog(
      slug: { current: { eq: $slug } }
    ) {
      pastGrantWinnerTitle
      publishDate(formatString: "LL")
      pastGrantWinnerImg: pageImageBlock {
        alt
        asset {
          gatsbyImageData
        }
      }
      pastGrantWinnerTextBlock: _rawPastGrantWinnerContent
    }
  }
`;
