module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.8.0_babel-plugin-styled-components@2.0.7_gatsby@5.13.6_reac_p766qzhyvltbfw5za5uiylybzy/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint-import-resolver-typescript@3.5.3_react-dom@18.3.1_react@18.3.1_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
