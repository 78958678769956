// npm
import React from 'react';
// import { HeadProps, graphql } from 'gatsby';
// import { PortableText } from '@portabletext/react';

// styles
import { StyledSectionTitle, Container, Card, Title, Label } from './styles';

// components
import SEO from '@shared/SEO';
import ContentBlock from '@components/molecules/ContentBlock';
import LayoutContainer from '@shared/layout/LayoutContainer';
import { StyledSection } from '@pages/home/styles';

// images
// import ImageContainer from '@components/atoms/images/ImageContainer';
// import Gallery from '@components/atoms/images/Gallery';

// styles
// import { StyledFAQ } from './styles';

// types
// import { ForumPageQuery } from './types';

function SasSubmissionGuidelinesPage() {
  return (
    <>
      <LayoutContainer hasSectionGaps>
        <StyledSection>
          <ContentBlock
            showCallToAction={true}
            callToActionLink="https://form.jotform.com/230654188700152"
            callToActionType="external"
            callToActionTitle="Ready? Awesome! Click Here to Upload Your Films"
          >
            <StyledSectionTitle>
              Student Animation Showcase 2024
            </StyledSectionTitle>
            <StyledSectionTitle>Submission Guidelines</StyledSectionTitle>
            <ul>
              <li>
                Please <strong>NO STUDENT DEMO REELS</strong>.
              </li>
              <li>
                We are only accepting submissions from films of graduating
                seniors.
              </li>
              <li>
                Add the List of the Student Films you are submitting in the
                order you want them to play.
              </li>
              <li>
                If there are multiple directors please add only one director as
                the primary director.
              </li>
              <li>Include only one film per senior student.</li>
            </ul>
            <StyledSectionTitle
              style={{ fontSize: 'var(--size-1n)', padding: '2rem' }}
            >
              File Formats
            </StyledSectionTitle>
            <Container>
              <Card>
                <Title>Short Film</Title>
                <p>
                  <Label>Size:</Label> 1920x1080
                </p>
                <p>
                  <Label>Format:</Label> .mov or MP4
                </p>
                <p>
                  <Label>Compression:</Label> H264
                </p>
              </Card>

              <Card>
                <Title>Still Image</Title>
                <p>Include one still image for each film submitted</p>
                <p>
                  <Label>Size:</Label> 1920x1080
                </p>
                <p>
                  <Label>Format:</Label> JPEG
                </p>
              </Card>

              <Card>
                <Title>School Logo</Title>
                <p>
                  <Label>Size:</Label> 1000x1000
                </p>
                <p>
                  <Label>Format:</Label> PNG transparent background
                </p>
              </Card>
            </Container>
            <StyledSectionTitle
              style={{ fontSize: 'var(--size-1n)', padding: '2rem' }}
            >
              Uploading Instructions
            </StyledSectionTitle>
            <ul>
              <li>
                Include all materials in <strong>(1) zip file</strong>. We will
                compile all of your student short films into a{' '}
                <strong>SINGLE SCHOOL SHOWREEL</strong>, adhering to the
                sequence you have provided.
              </li>
              <li>
                Please{` `}
                <a
                  href="https://drive.google.com/file/d/1sRBrsN04YYswb6vkRn-6ZNVaRgwYIJ6k/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download the Agreement
                </a>
                , fill out form and include it in your <strong>zip file</strong>
                .
              </li>
              <li>
                Upload <strong>(1) zip file</strong> with the requested
                materials inside it.{' '}
                <strong>The maximum file size for the zip file is 1 GB.</strong>{' '}
                If your file size is larger than 1 GB you will need to send
                multiple zip files each having a maximum file size of 1 GB.
              </li>
            </ul>
          </ContentBlock>
        </StyledSection>
      </LayoutContainer>
    </>
  );
}

export default SasSubmissionGuidelinesPage;

// a helper function Head used to generate SEO
export function Head() {
  // const { name } = data.forumPage;
  return <SEO title="Student Animation Showcase Submission Guidelines"></SEO>;
}
