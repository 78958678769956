import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { faShareAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopNavDropdown from '../TopNavDropdown';
import AsifaLogo from '@images/asifa.png';

const activeStyle = css`
  background: var(--c-yellow-1);
`;
const activeSocialStyle = css`
  color: var(--c-yellow-1);
  transform: scale(1.2);
`;

const StyledContentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: fit-content;
  margin-left: var(--size-1);
`;
const StyledSocialContainer = styled.div`
  position: absolute;
  display: flex;
  padding-right: var(--size-1);
  align-items: center;
  grid-gap: var(--size-1h);
  opacity: ${(props) => (props.$animate ? 0 : 1)};
  visibility: ${(props) => (props.$animate ? `hidden` : `visibile`)};
  right: ${(props) => (props.$animate ? `var(--size-1)` : `var(--size-1dn)`)};
  transition: all 0.3s var(--animation-bezier);
`;
const StyledSocialLink = styled.a`
  :hover svg {
    ${activeSocialStyle}
  }

  > svg {
    transition: all 0.3s var(--animation-bezier);
    color: var(--c-grey-4);
  }
`;
const StyledLinkContainer = styled.ul`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: var(--size-nudge);
  padding-inline-start: 0;
  padding-right: var(--size-half);
  transition: all 0.3s var(--animation-bezier);
  opacity: ${(props) => (props.$animate ? 0 : 1)};
  visibility: ${(props) => (props.$animate ? `hidden` : `visibile`)};
  transform: ${(props) =>
    props.$animate ? `translateX(var(--size-1))` : `translateX(0)`};
`;
const StyleLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  background: transparent;
  color: var(--c-dark-grey-3);
  border: none;
  padding: var(--size-nudge) var(--size-half);
  border-radius: var(--size-half);
  cursor: pointer;
  transition: background 0.3s var(--animation-bezier);

  :hover,
  :focus {
    ${activeStyle}
  }
`;
const StyledToggleContainer = styled.div`
  display: flex;
  grid-gap: var(--size-half);
  padding-left: var(--size-dual-nudge);
  border-left: solid 1px var(--c-grey-1);
`;
const StyledToggleButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  width: var(--size-1);
  padding: 0;

  :hover svg {
    ${activeSocialStyle}
  }

  > svg {
    transition: all 0.3s var(--animation-bezier);
    color: var(--c-grey-4);
  }
`;

const StyledAsifaLogo = styled.img`
  width: 2.5em;
  height: 2.5em;
  margin: -1em 0;
`;

export default function DesktopNavContent({
  location,
  navLinkData,
  socialMediaData,
}) {
  const [socialMediaToggle, setSocialMediaToggle] = useState(false);

  return (
    <StyledContentContainer className="desktopNav">
      <StyledLinkContainer $animate={socialMediaToggle}>
        <li>
          <TopNavDropdown
            buttonText="About"
            dropdownLinkData={navLinkData.about}
            location={location}
          />
        </li>
        <li>
          <TopNavDropdown
            buttonText="Engage"
            dropdownLinkData={navLinkData.engage}
            location={location}
          />
        </li>

        <li>
          <TopNavDropdown
            buttonText="Programs"
            dropdownLinkData={navLinkData.programs}
            location={location}
          />
        </li>
        <li>
          <StyleLink
            to="/join"
            activeStyle={{
              background: `var(--c-yellow-1)`,
            }}
          >
            Join Us
          </StyleLink>
        </li>
        <li>
          <StyleLink
            to="/contact"
            activeStyle={{
              background: `var(--c-yellow-1)`,
            }}
          >
            Contact
          </StyleLink>
        </li>
        <li>
          <a
            href="https://www.asifa-hollywood.org/"
            target="_blank"
            rel="norefer noopener noreferrer"
          >
            <StyledAsifaLogo src={AsifaLogo} alt="ASIFA-Hollywood Logo" />
          </a>
        </li>
      </StyledLinkContainer>
      <StyledSocialContainer $animate={!socialMediaToggle}>
        {socialMediaData.map((i, index) => (
          <StyledSocialLink
            key={index + i.icon}
            href={i.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={i.icon} />
          </StyledSocialLink>
        ))}
      </StyledSocialContainer>
      <StyledToggleContainer>
        <StyledToggleButton
          type="button"
          onClick={() => setSocialMediaToggle(!socialMediaToggle)}
        >
          {socialMediaToggle ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faShareAlt} />
          )}
        </StyledToggleButton>
        {/* TODO: Add Dark Mode Toggle */}
      </StyledToggleContainer>
    </StyledContentContainer>
  );
}
