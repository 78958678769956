import React from 'react';
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button';
import '@reach/menu-button/styles.css';
import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const activeStyle = css`
  background: var(--c-yellow-1);
`;

const StyledMenuButton = styled(MenuButton)`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.$menuIsActive ? `var(--c-yellow-1)` : `transparent`};
  color: var(--c-dark-grey-3);
  border: none;
  padding: var(--size-nudge) var(--size-half);
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: background 0.3s var(--animation-bezier);

  span {
    display: inherit;
  }

  :hover,
  :focus,
  &[data-reach-menu-button][aria-expanded='true'] {
    ${activeStyle}
  }

  &[data-reach-menu-button][aria-expanded='true'] .fa-chevron-down {
    transform: rotate(180deg);
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: var(--size-dual-nudge);
  margin-left: var(--size-nudge);
  transform: rotate(0deg);
  transition: transform 0.3s var(--animation-bezier);
`;

const menuListAnimation = keyframes`
  from {
      opacity: 0;
      transform: translateX(-12px);
    }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledMenuList = styled(MenuList)`
  padding: var(--size-1);
  margin-top: var(--size-1h);
  border: none;
  border-top: 2px solid var(--c-yellow-1);
  border-radius: 0 0 var(--radius-sm) var(--radius-sm);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.1);

  &[data-reach-menu-list] {
    animation: ${menuListAnimation} 0.6s var(--animation-bezier);
  }

  &[data-reach-menu-list],
  &[data-reach-menu-items] {
    display: flex;
    flex-direction: column;
    grid-gap: var(--size-1);
    transition: width 0.3s ease;
  }

  [data-reach-menu-item] {
    padding: 0;
    background: transparent;
    color: var(--c-grey-4);
    transition: 0.3s var(--animation-bezier);

    :hover,
    &[data-selected] {
      color: var(--c-yellow-2);
      transform: scale(1.03);
      font-weight: 600;
    }
  }
`;

function TopNavDropdown({ buttonText, dropdownLinkData, location }) {
  let onCurrentPage = false;
  if (location) {
    onCurrentPage = dropdownLinkData.some(
      (page) => page.url === location.pathname,
    );
  }

  return (
    <>
      {location && (
        <Menu>
          <StyledMenuButton $menuIsActive={onCurrentPage}>
            {buttonText}
            <span aria-hidden>
              <StyledFontAwesomeIcon icon={faChevronDown} />
            </span>
          </StyledMenuButton>

          <StyledMenuList>
            {dropdownLinkData.map((item, index) => {
              if (item.type === `gatsby`) {
                return (
                  <MenuLink
                    key={index + item.text}
                    as={Link}
                    to={item.url}
                    activeStyle={{
                      color: `var(--c-yellow-2)`,
                      fontWeight: 600,
                    }}
                  >
                    {item.text}
                  </MenuLink>
                );
              }
              return (
                <MenuLink
                  key={index + item.text}
                  as="a"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.text}
                </MenuLink>
              );
            })}
          </StyledMenuList>
        </Menu>
      )}
    </>
  );
}

export default TopNavDropdown;
