import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import socialMediaData from '../shared/social-media';
import navLinkData from './nav-links';
import AEFLogoLight from '@svg/aef-full-lightmode-logo-lg-top-tag.svg';
import DesktopNavContent from '../atoms/NavContent/DesktopNavContent';
import MobileNavContent from '../atoms/NavContent/MobileNavContent';

const StyledAEFLogo = styled.img`
  max-height: var(--size-2);
  position: relative;
  z-index: 10;
`;
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--c-grey-1);
  padding: var(--size-half) 0;
  @media screen and (max-width: 768px) {
    padding: var(--size-dual-nudge) 0;
    height: 56px;
  }

  li {
    list-style: none;
  }

  .desktopNav {
    display: flex;
  }
  .mobileNav {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .desktopNav {
      display: none;
    }
    .mobileNav {
      display: flex;
    }
  }
`;

export default function Nav({ location, menuOpen, setMenuOpen }) {
  return (
    <StyledNav>
      <Link to="/">
        <StyledAEFLogo src={AEFLogoLight} alt="AEF Logo" />
      </Link>
      <DesktopNavContent
        location={location}
        socialMediaData={socialMediaData}
        navLinkData={navLinkData}
      />
      <MobileNavContent
        socialMediaData={socialMediaData}
        navLinkData={navLinkData}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
    </StyledNav>
  );
}
