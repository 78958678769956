import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/atoms/buttons/Button';

export const StyledTwoColumnSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--size-2h);
  width: 100%;

  & > * {
    max-width: calc(50% - var(--size-1n));
  }
  @media only screen and (width <= 768px) {
    & > * {
      max-width: 100%;
    }
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: var(--size-2h);
  width: 100%;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column; /* Change to column layout for mobile devices */
  align-items: center;
  justify-content: space-between;
  gap: var(--size-nudge);
  position: relative;
  width: 100%;

  input {
    padding-left: var(--size-2n);
    width: 100%; /* Ensure input takes full width */
    max-width: 250px; /* Limit input width for larger screens */
    margin-bottom: var(
      --size-nudge
    ); /* Add margin between input and buttons on mobile */
  }

  @media screen and (min-width: 768px) {
    input {
      max-width: 600px; /* Adjust maximum width for desktop and iPad */
    }

    flex-direction: row; /* Change to row layout for larger screens */
    justify-content: space-between;
    align-items: center;
  }
`;

export const SearchButtonsContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically on mobile */
  align-items: center;
  margin: 0 auto;
  max-width: 600px;

  button {
    margin-bottom: 1rem; /* Add margin between buttons */
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;

    button {
      margin-bottom: 0; /* Reset margin for larger screens */
      margin-left: 1rem; /* Add space between buttons */
    }
  }
`;

// for grid and list buttons
export const LayoutButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0;
    margin-left: var(--size-nudge);

    button {
      margin-left: 2rem; /* increase space between buttons on larger screens */
    }
  }

  /* Add space between buttons on mobile */
  button {
    margin-top: 5px; /* Adjust as needed */
  }
`;

export const StyledButton = styled(Button)`
  /* Your button styles */
`;

export const StyledSearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: var(--size-dual-nudge);
  color: var(--c-grey-3);
  font-size: var(--size-1);
`;
