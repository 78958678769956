import styled from 'styled-components';

export const StyledSectionTitle = styled.h2`
  color: var(--c-dark-grey-3);
  text-transform: capitalize;
`;

// export const CheckmarkBulletList = styled.ul`
//   list-style-type: none;
//   padding-left: 0;
// `;

// export const CheckmarkBulletListItem = styled.li`
//   &::before {
//     content: '✓';
//     display: inline-block;
//     width: 1em;
//     height: 1em;
//     margin-left: -1em;
//     color: #7a92a1;
//     margin-right: 0.5em;
//   }
// `;

export const FormatSubmissionContainer = styled.div`
  margin-bottom: 20px;
`;

export const FormatInfo = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Card = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px); /* Adjust the width as needed */
  flex-grow: 1;

  @media (max-width: 768px) {
    width: calc(50% - 20px);
  }
`;

export const Title = styled.h3`
  /* font-size: 1rem; */
  /* margin-bottom: 10px; */
`;

export const Label = styled.strong`
  margin-right: 5px;
`;
