// npm
import { graphql } from 'gatsby';
import React from 'react';
// import { PortableText } from '@portabletext/react';

// components
import SEO from '@components/shared/SEO';
import HeroHeading from '@components/molecules/HeroHeading';
// import ImageContainer from '@components/atoms/images/ImageContainer';
import Button from '@components/atoms/buttons/Button';
// import ContentBlock from '@components/molecules/ContentBlock';

// styles

// types
// import { BooksTemplateProps } from './types';

const BooksTemplate = ({ data }) => {
  const {
    title,
    // booksPostImg,
    isbn,
    // summary,
    publisher,
    category,
    dateOfPublication,
    author,
    // format,
  } = data.books;
  return (
    <>
      <SEO title={title} />
      <HeroHeading>
        <h1>{title}</h1>
        {/* {booksPostImg ? ( */}
        {/*   <ImageContainer */}
        {/*     image={booksPostImg?.asset?.gatsbyImageData ?? null} */}
        {/*     type="dynamic" */}
        {/*     alt={booksPostImg?.alt ? booksPostImg.alt : ``} */}
        {/*   /> */}
        {/* ) : null} */}
        <p>
          <strong>ISBN:</strong> {isbn}
        </p>
        <p>
          <strong>Publisher:</strong> {publisher}
        </p>
        <p>
          <strong>Categories:</strong> {category.join(`, `)}
        </p>
        <p>
          <strong>Author:</strong> {author}
        </p>
        {/* <p> */}
        {/*   <strong>Format:</strong> {format} */}
        {/* </p> */}
        <p>
          <strong>Date of Publication: </strong> {dateOfPublication}
        </p>

        {/* <p>Summary: {summary}</p> */}
        <div
          style={{
            display: `flex`,
            gap: `var(--size-dual-nudge)`,
          }}
        >
          <Button theme="outline" actionType="internal" link="/books">
            All Books
          </Button>
        </div>
      </HeroHeading>
    </>
  );
};

export default BooksTemplate;

export const query = graphql`
  query ($slug: String!) {
    books: sanityBooks(slug: { current: { eq: $slug } }) {
      id
      title
      # booksPostImg: imageUrl {
      #   alt
      #   asset {
      #     gatsbyImageData
      #   }
      # }
      isbn
      # summary
      publisher
      category
      dateOfPublication
      author
      # format
    }
  }
`;
