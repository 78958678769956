import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisuallyHidden from '@reach/visually-hidden';
import { linkMapper } from '@utils/link-mapper';
import AsifaLogo from '@images/asifa.png';

// CSS styles for active social links
const activeSocialStyle = css`
  color: var(--c-yellow-1);
  transform: scale(1.2);
`;

// Container for the menu button
const StyledMenuButtonContainer = styled.div`
  display: flex;
  grid-gap: var(--size-half);
  padding-left: var(--size-dual-nudge);
  border-left: solid 1px var(--c-grey-1);
  margin-left: var(--size-1);
  z-index: 10;
`;

// Button for toggling the menu
const StyledToggleButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  height: var(--size-1dn);
  width: var(--size-2);
  transition: all 0.3s var(--animation-bezier);

  > div,
  > div::before,
  > div::after {
    transition: all 0.3s var(--animation-bezier);
    width: var(--size-1dn);
    height: 3px;
    border-radius: 5px;
    background: var(--c-yellow-1);
    position: absolute;
  }

  > div::before,
  > div::after {
    content: '';
    left: 0;
  }

  > div::before {
    transform: translateY(-8px);
  }

  > div::after {
    transform: translateY(8px);
  }

  .open {
    transform: rotate(45deg);
  }

  .open::before {
    transform: rotate(-270deg);
    left: 0;
  }

  .open::after {
    transform: translateY(16px);
    opacity: 0;
  }
`;

// Sidebar container for the menu
const StyledMenuSidebar = styled.div`
  height: 100%;
  width: 100%;
  transform: ${(props) =>
    props.$open ? `translateX(0))` : `translateX(-100%)`};
  visibility: ${(props) => (props.$open ? `visible` : `hidden`)};
  position: fixed;
  z-index: 1;
  top: 56px;
  left: 0;
  background-color: var(--c-white);
  transition: all 0.4s var(--animation-bezier);
  overflow-x: hidden;
`;

// Container for the menu content
const StyledMenuContainer = styled.div`
  padding: var(--size-1) var(--size-2) var(--size-2);
  overflow-x: scroll;
  height: calc(100vh - var(--size-3));
`;

// Heading for the menu links
const StyledLinkHeading = styled.h2`
  color: var(--c-dark-grey-4);
  font-size: var(--size-1);
  font-weight: 600;
  line-height: var(--size-2dn);
`;

// Container for the menu links
const StyledLinkContainer = styled.ul`
  font-weight: 600;
  padding: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-bottom: var(--size-1);
  display: flex;
  flex-direction: column;
  gap: var(--size-half);
`;

// Container for social media links
const StyledSocialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-top: var(--size-2h);
  gap: var(--size-2) var(--size-1);
`;

// Social media link
const StyledSocialLink = styled.a`
  :hover svg {
    ${activeSocialStyle}
  }

  > svg {
    transition: all 0.3s var(--animation-bezier);
    color: var(--c-grey-4);
    font-size: var(--size-2);
  }
`;

// Logo image for ASIFA-Hollywood
const StyledAsifaLogo = styled.img`
  width: 2.5em;
  height: 2.5em;
`;

export default function MobileNavContent({
  navLinkData,
  socialMediaData,
  menuOpen,
  setMenuOpen,
}) {
  return (
    <>
      {/* Container for the menu button */}
      <StyledMenuButtonContainer className="mobileNav">
        {/* Toggle button for opening/closing the menu */}
        <StyledToggleButton
          type="button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {/* Visually hidden text for screen readers */}
          <VisuallyHidden>
            {menuOpen ? `Open nav menu` : `Close nav menu`}
          </VisuallyHidden>
          {/* Button icon */}
          <div className={menuOpen ? `open` : `close`} />
        </StyledToggleButton>
      </StyledMenuButtonContainer>
      {/* Sidebar container for the menu */}
      <StyledMenuSidebar $open={menuOpen}>
        {/* Container for the menu content */}
        <StyledMenuContainer>
          {/* Heading for the "About" section */}
          <StyledLinkHeading>About</StyledLinkHeading>
          {/* Container for the "About" links */}
          <StyledLinkContainer>
            {/* Render the "About" links */}
            {linkMapper(navLinkData.about, () => setMenuOpen(false))}
          </StyledLinkContainer>

          {/* Heading for the "Engage" section */}
          <StyledLinkHeading>Engage</StyledLinkHeading>
          {/* Container for the "Engage" links */}
          <StyledLinkContainer>
            {/* Render the "Engage" links */}
            {linkMapper(navLinkData.engage, () => setMenuOpen(false))}
          </StyledLinkContainer>

          {/* Heading for the "Programs" section */}
          <StyledLinkHeading>Programs</StyledLinkHeading>
          {/* Container for the "Programs" links */}
          <StyledLinkContainer>
            {/* Render the "Programs" links */}
            {linkMapper(navLinkData.programs, () => setMenuOpen(false))}
          </StyledLinkContainer>

          {/* Heading for the "Connect" section */}
          <StyledLinkHeading>Connect</StyledLinkHeading>
          {/* Container for the "Connect" links */}
          <StyledLinkContainer>
            {/* Render the "Connect" links */}
            {linkMapper(navLinkData.forms, () => setMenuOpen(false))}
          </StyledLinkContainer>

          {/* Container for social media links */}
          <StyledSocialContainer>
            {/* ASIFA-Hollywood logo */}
            <div>
              <a
                href="https://www.asifa-hollywood.org/"
                target="_blank"
                rel="norefer noopener noreferrer"
              >
                {/* ASIFA-Hollywood logo image */}
                <StyledAsifaLogo src={AsifaLogo} alt="ASIFA-Hollywood Logo" />
              </a>
            </div>
            {/* Render social media links */}
            {socialMediaData.map((i, index) => (
              <StyledSocialLink
                key={index + i.icon}
                href={i.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={i.icon} />
              </StyledSocialLink>
            ))}
          </StyledSocialContainer>
        </StyledMenuContainer>
      </StyledMenuSidebar>
    </>
  );
}
