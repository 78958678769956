// npm
import React from 'react';

// components
// import Button from '@components/atoms/buttons/Button';
// import Tag from '@components/atoms/tags/Tag';

// utility
// import truncateText from '@utils/truncateText/truncateText';

// styles
import { StyledBookContentContainer, StyledMetaContainer } from './styles';

// types
import { CardContentProps } from './types';

const BookCardContent: React.FC<CardContentProps> = ({
  cardType,
  // actionType,
  // tagText,
  // title,
  isbn,
  publisher,
  author,
  dateOfPublication,
  category,
  // description,
  // charLimitTitle,
  // charLimitDesc,
}) => {
  return (
    <StyledBookContentContainer
      style={
        cardType === `pastEvent`
          ? {
              borderTop: `none`,
            }
          : undefined
      }
    >
      {/* comment to removes */}
      <StyledMetaContainer>
        {author && (
          <span style={{ fontSize: `15px`, color: 'black' }}>
            <strong>Author:</strong> {author}
          </span>
        )}
        {category && (
          <span style={{ fontSize: `15px`, color: 'black' }}>
            <strong>Subject:</strong> {category}
          </span>
        )}

        {isbn && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>ISBN:</strong> {isbn}
          </span>
        )}
        {publisher && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>Publisher:</strong> {publisher}
          </span>
        )}
        {dateOfPublication && (
          <span style={{ fontSize: `15px`, color: `black` }}>
            <strong>Date Of Publication:</strong> {dateOfPublication}
          </span>
        )}
        {/* {tagText && (
          <Tag
            type="primary"
            // add below line back if you want past events to be grey and new events to be yellow
            // color={cardType === `pastEvent` ? `grey` : undefined}
            text={tagText}
          />
        )} */}
        {/* <h3 title={title}>{truncateText(charLimitTitle, title)}</h3> */}
        {/* {description && (
          <p title={description}>{truncateText(charLimitDesc, description)}</p>
        )} */}
      </StyledMetaContainer>
      {/* <Button
        actionType={actionType === `link` ? `link` : `anchor`}
        theme="minor"
        disable
      >
        {cardType === `resource` ? `View resource` : `Learn more`}
      </Button> */}
    </StyledBookContentContainer>
  );
};

export default BookCardContent;
