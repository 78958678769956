import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledLinks = styled.li`
  a {
    font-weight: 400;
    text-decoration: none;
    display: block;
  }
`;

// the onClick event handler is passed to the Link component for Gatsby links and the a element for external links. This allows you to handle the closing of the mobile nav when any of the links are clicked on.
export const linkMapper = (data, onClick) =>
  data.map((item, index) => {
    if (item.type === `gatsby`) {
      return (
        <StyledLinks key={index + item.text}>
          <Link
            to={item.url}
            activeStyle={{ color: `var(--c-yellow-2)`, fontWeight: 600 }}
            onClick={onClick}
          >
            {item.text}
          </Link>
        </StyledLinks>
      );
    }
    return (
      <StyledLinks key={index + item.text}>
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {item.text}
        </a>
      </StyledLinks>
    );
  });
