import styled from 'styled-components';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-2h);
  width: 100%;
`;
// const StyledSectionTitle = styled.h2`
//   color: var(--c-dark-grey-3);
//   text-transform: capitalize;
// `;
export const StyledEventDescription = styled.div`
  p {
    line-height: 1.5;
    font-weight: normal;
    text-align: left;
  }
`;
