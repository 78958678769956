// npm
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { HeadProps, PageProps, graphql, Link } from 'gatsby';
import { faSearch, faTh, faList } from '@fortawesome/free-solid-svg-icons';
// import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
// components
import SEO from '@shared/SEO';
import HeroHeading from '@components/molecules/HeroHeading';
// import Pagination from '@components/shared/Pagination';
import LayoutContainer from '@shared/layout/LayoutContainer';
import Logo from '@components/atoms/Logo';
import ContentBlock from '@components/molecules/ContentBlock';
import Button from '@components/atoms/buttons/Button';
// images
import ImageContainer from '@components/atoms/images/ImageContainer';
// import Gallery from '../components/atoms/Gallery';
// styles
import {
  StyledForm,
  StyledSearchIcon,
  StyledSection,
  SearchButtonsContainer,
  LayoutButtonsContainer,
  StyledButton,
} from './styles';
import { StyledInput } from '@components/atoms/forms/FormInput/styles';
import MediumCard from '@components/molecules/cards/MediumCard';
import CardGridContainer from '@components/molecules/cards/CardGridContainer/styles';
// types
import { BooksPageQuery, SanityBook } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// utilities
import { capitalizeEachWord } from '@utils/capitalizeEachWord';

const BooksPage: React.FC<PageProps<BooksPageQuery>> = ({
  data,
  // pageContext,
}) => {
  const {
    // name,
    headingOne,
    subheading,
    // galleryManyBlock,
    // hasGallery,
    pageImageBlock,
  } = data.booksPage;

  const [bookPosts, setBookPosts] = useState<SanityBook[]>(
    data.allSanityBooks.nodes,
  ); // Use SanityBook type
  const [searchTerm, setSearchTerm] = useState<string>(``);
  const [searchMode, setSearchMode] = useState<'title' | 'author' | 'category'>(
    'title',
  ); // State to track search mode
  const [searchCount, setSearchCount] = useState<number | null>(null);
  // cards
  const [isGrid, setIsGrid] = useState(true);
  const searchInputRef = useRef<HTMLInputElement>(null);

  // const [showPagination, setShowPagination] = useState<boolean>(true);
  // const pageSize = process.env.GATSBY_PAGE_SIZE
  //   ? parseInt(process.env.GATSBY_PAGE_SIZE)
  //   : 10;

  const handleGridClick = () => {
    setIsGrid(true);
  };

  const handleListClick = () => {
    setIsGrid(false);
  };

  const searchBooks = useCallback(
    (e: React.FormEvent) => {
      e?.preventDefault?.();

      if (!searchTerm.trim()) {
        setBookPosts(data.allSanityBooks.nodes);
        setSearchCount(null);
        // setShowPagination(true);
      } else {
        // setShowPagination(false);
        const items = data?.searchableBooks?.nodes?.filter(
          (article: SanityBook) => {
            const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

            if (searchMode === 'title') {
              return article.title
                ?.trim()
                .toLowerCase()
                .includes(lowerCaseSearchTerm);
            } else if (searchMode === 'author') {
              return article.author
                ?.trim()
                .toLowerCase()
                .includes(lowerCaseSearchTerm);
            } else if (searchMode === 'subject') {
              const isInCategory = article.category
                ?.map((cat) => cat.trim().toLowerCase())
                .includes(lowerCaseSearchTerm);
              return isInCategory;
            } else {
              // Return false if searchMode doesn't match any expected value
              return false;
            }
          },
        );
        setBookPosts(items || []);
        setSearchCount(items?.length || 0); // Update search count when new search is performed
      }
    },
    [data, searchTerm, searchMode],
  );

  useEffect(() => {
    const event = {} as React.FormEvent<HTMLFormElement>;

    // If the search term is not present, show just 5 books
    if (!searchTerm.trim()) {
      // only show 1 book
      // setBookPosts([data.allSanityBooks.nodes[0]]);
      setBookPosts(data.allSanityBooks.nodes.slice(0, 4));
      // setShowPagination(false);
    } else {
      // If the search term is present, perform the search
      searchBooks(event);
    }
  }, [searchTerm, searchBooks, data.allSanityBooks.nodes]);

  useEffect(() => {
    const inputElement = searchInputRef.current;
    const handleInputChange = () => {
      if (inputElement && inputElement.value === '') {
        setSearchTerm('');
        setBookPosts(data.allSanityBooks.nodes);
        setSearchCount(null);
      }
    };

    if (inputElement) {
      inputElement.addEventListener('input', handleInputChange);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('input', handleInputChange);
      }
    };
  }, [data.allSanityBooks.nodes]);

  return (
    <>
      <HeroHeading>
        <Logo />
        <h1>{headingOne}</h1>
        <p>{subheading}</p>
        <p style={{ fontSize: `15px`, fontWeight: 100, lineHeight: '1.5em' }}>
          The Animation Book Database lists animation books recommended by
          teachers and professionals in the animation community. Inclusion in
          the database does not imply endorsement, and exclusion does not
          indicate disapproval. The database will continue to grow with new
          recommendations from the animation education community. To suggest a
          book for inclusion, please use the{' '}
          <Link to="#suggest-a-book" style={{ color: `var(--c-yellow-2)` }}>
            Suggest A Book
          </Link>{' '}
          button at the bottom of this page.
        </p>
        {pageImageBlock?.asset?.gatsbyImageData ? (
          <ImageContainer
            image={pageImageBlock?.asset?.gatsbyImageData ?? null}
            type="dynamic"
            alt={pageImageBlock?.alt ? pageImageBlock.alt : ``}
          />
        ) : null}
        <SearchButtonsContainer>
          <Button actionType="button" onClick={() => setSearchMode('title')}>
            Search by Title
          </Button>
          <Button actionType="button" onClick={() => setSearchMode('subject')}>
            Search by Subject
          </Button>
          <Button actionType="button" onClick={() => setSearchMode('author')}>
            Search by Author
          </Button>
        </SearchButtonsContainer>
        <StyledForm onSubmit={searchBooks} role="search">
          <StyledInput
            ref={searchInputRef}
            id="searchInput"
            style={{
              background: `var(--c-white)`,
            }}
            type="search"
            value={searchTerm}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setSearchTerm((e.target as HTMLInputElement).value)
            }
            placeholder={`Search by ${
              searchMode === 'title'
                ? 'Title'
                : searchMode === 'author'
                ? 'Author'
                : 'Subject'
            }`}
            aria-label={`Search By ${
              searchMode === 'title'
                ? 'Title'
                : searchMode === 'author'
                ? 'Author'
                : 'Subject'
            }`}
          />
          <StyledSearchIcon icon={faSearch} />
          <LayoutButtonsContainer>
            <StyledButton onClick={handleGridClick}>
              <FontAwesomeIcon icon={faTh} />
              Grid
            </StyledButton>
            <StyledButton onClick={handleListClick}>
              <FontAwesomeIcon icon={faList} />
              List
            </StyledButton>
          </LayoutButtonsContainer>
        </StyledForm>
      </HeroHeading>

      <LayoutContainer hasSectionGaps>
        {searchCount !== null && (
          <h2 style={{ fontWeight: 'normal' }}>
            Your{' '}
            <span
              style={{
                // backgroundColor: 'var(--c-yellow-1)',
                // padding: '0.1em 0.5em',
                fontWeight: 'bold',
              }}
            >
              {searchMode === 'title'
                ? 'Title'
                : searchMode === 'author'
                ? 'Author'
                : 'Subject'}
            </span>{' '}
            search request returned{' '}
            <span style={{ fontWeight: 'bold' }}>{searchCount}</span>{' '}
            {searchCount === 1 ? 'book' : 'books'}
          </h2>
        )}
        {bookPosts?.length ? (
          <>
            {isGrid ? (
              // <StyledTwoColumnSection>
              <CardGridContainer>
                {bookPosts.map((b) => {
                  console.log(b);
                  return (
                    <MediumCard
                      cardType={b._type}
                      key={b.id}
                      title={b.title}
                      author={b.author}
                      publisher={b.publisher}
                      isbn={b.isbn}
                      dateOfPublication={b.dateOfPublication}
                      category={b.category.map(capitalizeEachWord).join(`, `)}
                      // description={b.summary}
                      to={`/books/${b.slug.current}`}
                      // image={
                      //   b?.booksImg?.asset?.gatsbyImageData ? (
                      //     <GatsbyImage
                      //       image={b.booksImg.asset.gatsbyImageData}
                      //       alt={b.title}
                      //     />
                      //   ) : (
                      //     <StaticImage
                      //       src="https://cdn.sanity.io/images/l8ll9i2k/production/60dc8a46e7296059b0ad81c11ca8b4cc9cdb72ab-564x268.jpg?w=564&h=268&auto=format"
                      //       alt={b.title}
                      //       height={134}
                      //       width={282}
                      //     />
                      //   )
                      // }
                    />
                  );
                })}
              </CardGridContainer>
            ) : (
              <div>
                {bookPosts.map((b) => (
                  <div
                    key={b.id}
                    style={{
                      borderBottom: '1px solid #ccc',
                      paddingLeft: '10px',
                      paddingBottom: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <h3>{b.title}</h3>
                    <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                      {b.author && (
                        <strong
                          style={{ fontSize: '16px', color: 'var(--size-1)' }}
                        >
                          Author:
                        </strong>
                      )}{' '}
                      {b.author}
                    </p>
                    <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                      {b.category && (
                        <strong
                          style={{ fontSize: '16px', color: 'var(--size-1)' }}
                        >
                          Subject:
                        </strong>
                      )}{' '}
                      {b.category.map(capitalizeEachWord).join(`, `)}
                    </p>

                    <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                      {b.publisher && (
                        <strong
                          style={{ fontSize: '16px', color: 'var(--size-1)' }}
                        >
                          Publisher:
                        </strong>
                      )}{' '}
                      {b.publisher || '-'}{' '}
                      {/* Display '-' if publisher is null/undefined */}
                    </p>
                    <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                      {b.isbn && (
                        <strong
                          style={{ fontSize: '16px', color: 'var(--size-1)' }}
                        >
                          ISBN:
                        </strong>
                      )}{' '}
                      {b.isbn || '-'}{' '}
                      {/* Display '-' if isbn is null/undefined */}
                    </p>
                    <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                      {b.dateOfPublication && (
                        <strong
                          style={{ fontSize: '16px', color: 'var(--size-1)' }}
                        >
                          Date of Publication:
                        </strong>
                      )}{' '}
                      {b.dateOfPublication}
                    </p>
                    {/* <p> */}
                    {/*   <a href={`/books/${b.slug.current}`}>View Details</a> */}
                    {/* </p> */}
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <StyledSection>
            <h2>No Books Found</h2>
          </StyledSection>
        )}
        {/* {showPagination ? (
          <StyledSection>
            <Pagination
              pageSize={pageSize}
              totalCount={data.allSanityBooks.totalCount}
              currentPage={
                (pageContext && `currentPage` in pageContext
                  ? pageContext.currentPage
                  : 1) as number
              }
              skip={
                (pageContext && `skip` in pageContext
                  ? pageContext.skip
                  : 0) as number
              }
              base="/books"
              visible={true}
            />
            {` `}
          </StyledSection>
        ) : null} */}
        <StyledSection>
          {/* {hasGallery ? <Gallery images={galleryManyBlock} /> : null} */}
          <ContentBlock
            showCallToAction={true}
            callToActionLink="/contact?source=add-book"
            callToActionType="internal"
            callToActionTitle="Suggest a Book"
          >
            <div
              id="suggest-a-book"
              style={{ fontWeight: `bold`, fontSize: `18px` }}
            >
              If you have an Animation Book you would like to suggest please
              click on the button below:
            </div>
          </ContentBlock>
        </StyledSection>
      </LayoutContainer>
    </>
  );
};

export default BooksPage;

// a helper function Head used to generate SEO
export function Head({ data }: HeadProps) {
  // Check if data exists and has the booksPage property
  if (data && `booksPage` in data) {
    const { name } = data.booksPage as { name: string };
    return <SEO title={name}></SEO>;
  }

  // Handle the case where data or booksPage property is missing
  return <div>Error: Data is missing or has incorrect structure</div>;
}

export const query = graphql`
  query BooksPageQuery($skip: Int = 0, $pageSize: Int = 10) {
    booksPage: sanityPage(name: { eq: "Books" }) {
      name
      headingOne
      subheading
      pageImageBlock {
        alt
        asset {
          id
          gatsbyImageData
        }
        hotspot {
          y
          x
          width
          height
        }
        crop {
          top
          right
          left
          bottom
        }
      }
      hasGallery
      galleryManyBlock {
        alt
        asset {
          id
          gatsbyImageData
        }
        crop {
          top
          right
          left
          bottom
        }
        hotspot {
          y
          x
          width
          height
        }
      }
    }
    allSanityBooks(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        id
        _type
        slug {
          current
        }
        title
        author
        isbn
        dateOfPublication
        publisher
        category
        # summary
        # booksImg: imageUrl {
        #  asset {
        #    gatsbyImageData
        #  }
        # }
      }
    }
    searchableBooks: allSanityBooks {
      nodes {
        id
        _type
        slug {
          current
        }
        title
        author
        isbn
        dateOfPublication
        publisher
        category
        # booksImg: imageUrl {
        #   alt
        #   asset {
        #     gatsbyImageData
        #   }
        # }
      }
    }
  }
`;
