const navLinkData = {
  programs: [
    {
      type: `gatsby`,
      url: `/scholarships`,
      text: `Student Scholarship`,
    },
    {
      type: `gatsby`,
      url: `/past-scholarship-winners`,
      text: `Past Scholarship Winners`,
    },
    {
      type: `gatsby`,
      url: `/grants`,
      text: `Faculty Grants`,
    },
    {
      type: `gatsby`,
      url: `/past-grant-winners`,
      text: `Past Grant Winners`,
    },
    {
      type: `gatsby`,
      url: `/student-animation-showcase`,
      text: `Student Animation Showcase`,
    },
    // {
    //   type: 'external',
    //   url: 'https://www.cafepress.com/',
    //   text: 'AEF Merch',
    // },
  ],
  engage: [
    {
      type: `gatsby`,
      url: `/forum`,
      text: `The Forum`,
    },
    {
      type: `gatsby`,
      url: `/events`,
      text: `Events`,
    },
    {
      type: `gatsby`,
      url: `/news`,
      text: `News`,
    },
    {
      type: `gatsby`,
      url: `/events/birds-of-a-feather-discussion`,
      text: `Birds of a Feather`,
    },
    {
      type: `gatsby`,
      url: `/events/animation-salon`,
      text: `Animation Salon`,
    },
    {
      type: `gatsby`,
      url: `/books`,
      text: `Animation Book Database`,
    },
  ],
  about: [
    {
      type: `gatsby`,
      url: `/about`,
      text: `Who We Are`,
    },
    {
      type: `gatsby`,
      url: `/history`,
      text: `AEF History`,
    },
    {
      type: `gatsby`,
      url: `/legends`,
      text: `AEF Hall Of Fame`,
    },
  ],
  forms: [
    {
      type: `gatsby`,
      url: `/join`,
      text: `Join`,
    },
    {
      type: `gatsby`,
      url: `/contact`,
      text: `Contact`,
    },
  ],
};

export default navLinkData;
