exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-books-index-tsx": () => import("./../../../src/pages/books/index.tsx" /* webpackChunkName: "component---src-pages-books-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-forum-index-tsx": () => import("./../../../src/pages/forum/index.tsx" /* webpackChunkName: "component---src-pages-forum-index-tsx" */),
  "component---src-pages-grants-index-tsx": () => import("./../../../src/pages/grants/index.tsx" /* webpackChunkName: "component---src-pages-grants-index-tsx" */),
  "component---src-pages-history-index-tsx": () => import("./../../../src/pages/history/index.tsx" /* webpackChunkName: "component---src-pages-history-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-legends-index-tsx": () => import("./../../../src/pages/legends/index.tsx" /* webpackChunkName: "component---src-pages-legends-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-past-grant-winners-index-tsx": () => import("./../../../src/pages/past-grant-winners/index.tsx" /* webpackChunkName: "component---src-pages-past-grant-winners-index-tsx" */),
  "component---src-pages-past-scholarship-winners-index-tsx": () => import("./../../../src/pages/past-scholarship-winners/index.tsx" /* webpackChunkName: "component---src-pages-past-scholarship-winners-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-sas-submission-guidelines-index-tsx": () => import("./../../../src/pages/sas-submission-guidelines/index.tsx" /* webpackChunkName: "component---src-pages-sas-submission-guidelines-index-tsx" */),
  "component---src-pages-scholarships-index-tsx": () => import("./../../../src/pages/scholarships/index.tsx" /* webpackChunkName: "component---src-pages-scholarships-index-tsx" */),
  "component---src-pages-student-animation-showcase-index-tsx": () => import("./../../../src/pages/student-animation-showcase/index.tsx" /* webpackChunkName: "component---src-pages-student-animation-showcase-index-tsx" */),
  "component---src-pages-thank-you-index-tsx": () => import("./../../../src/pages/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-thank-you-index-tsx" */),
  "component---src-templates-books-template-books-template-tsx": () => import("./../../../src/templates/BooksTemplate/BooksTemplate.tsx" /* webpackChunkName: "component---src-templates-books-template-books-template-tsx" */),
  "component---src-templates-event-template-event-template-tsx": () => import("./../../../src/templates/EventTemplate/EventTemplate.tsx" /* webpackChunkName: "component---src-templates-event-template-event-template-tsx" */),
  "component---src-templates-news-template-news-template-tsx": () => import("./../../../src/templates/NewsTemplate/NewsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-template-news-template-tsx" */),
  "component---src-templates-past-grant-winner-template-past-grant-winner-template-tsx": () => import("./../../../src/templates/PastGrantWinnerTemplate/PastGrantWinnerTemplate.tsx" /* webpackChunkName: "component---src-templates-past-grant-winner-template-past-grant-winner-template-tsx" */),
  "component---src-templates-past-scholarship-winner-template-past-scholarship-winner-template-tsx": () => import("./../../../src/templates/PastScholarshipWinnerTemplate/PastScholarshipWinnerTemplate.tsx" /* webpackChunkName: "component---src-templates-past-scholarship-winner-template-past-scholarship-winner-template-tsx" */)
}

