// npm
import React from 'react';
import { HeadProps, PageProps, graphql } from 'gatsby';
import { PortableText } from '@portabletext/react';
import { dateFormatter } from '@utils/dateFormatter/dateFormatter';

// components
import SEO from '@shared/SEO';
import LayoutContainer from '@shared/layout/LayoutContainer';
import HeroHeading from '@components/molecules/HeroHeading';
import ContentBlock from '@components/molecules/ContentBlock';
import Logo from '@components/atoms/Logo';
import Button from '@components/atoms/buttons/Button';

// images
import ImageContainer from '@components/atoms/images/ImageContainer';
import sasEventImg from '@images/sas-2024-v5.jpg';

// styles
import {
  SpecialButton,
  StyledSection,
  ListInline,
  ListItem,
  RedBoldText,
} from './styles';

// types
import { SASPageQuery } from './types';

const StudentAnimationShowcasePage: React.FC<PageProps<SASPageQuery>> = ({
  data,
}) => {
  const {
    headingOne,
    headingOneButtonPath,
    headingOneButtonText,
    subheading,
    headingTwo,
    pageImageBlock,
    sasTextBlock,
    sasPremierBlocks,
  } = data.sasPage;

  const currentDate = new Date();

  // Sort SAS premier blocks by premierDate in descending order
  const sortedBlocks = sasPremierBlocks.sort(
    (a, b) => new Date(b.premierDate) - new Date(a.premierDate),
  );

  // Initialize variables to store content blocks for current and previous years
  let currentYearBlock = null;
  let previousYearBlock = null;

  // Determine the most recent past event and the next future event
  let mostRecentPastEvent = null;
  let nextFutureEvent = null;

  for (const s of sortedBlocks) {
    const premierDate = new Date(s.premierDate);

    if (premierDate <= currentDate && !mostRecentPastEvent) {
      mostRecentPastEvent = s;
    } else if (premierDate > currentDate && !nextFutureEvent) {
      nextFutureEvent = s;
    }

    if (mostRecentPastEvent && nextFutureEvent) {
      break;
    }
  }

  // Create content blocks for current and previous years
  if (nextFutureEvent) {
    currentYearBlock = (
      <ContentBlock
        showCallToAction={true}
        callToActionLink="https://form.jotform.com/230654188700152"
        callToActionType="external"
        key={Math.random()}
      >
        <div key={Math.random()} style={{ textAlign: 'center' }}>
          <h2>{nextFutureEvent.title}</h2>
          <h3>
            Friday {dateFormatter(nextFutureEvent.premierDate)} from 7:00 PM to
            8:30 PM PDT
            <br />
            <br />
            Zoom Link:{' '}
            <a href="https://us02web.zoom.us/j/83423801319?pwd=9wWWb8vWbarsiBbfELld45pLdeobJ3.1">
              Premiere Panel Event
            </a>
            <br />
            <br />
            Once the showcase goes live the films will be available for viewing
            for the rest of{' '}
            {new Date(nextFutureEvent.premierDate).getFullYear()} year.
          </h3>
          <p>
            <img
              src={sasEventImg}
              alt={`Student Animation Showcase Premiere Event ${new Date(
                nextFutureEvent.premierDate,
              ).getFullYear()}`}
            />
          </p>
        </div>
      </ContentBlock>
    );
  }

  if (mostRecentPastEvent) {
    const schoolsList = mostRecentPastEvent.schools.map((school) => (
      <ListItem key={Math.random()}>{school}</ListItem>
    ));

    previousYearBlock = (
      <ContentBlock
        showCallToAction={true}
        callToActionLink="https://vimeo.com/971942644"
        callToActionType="external"
        callToActionTitle={`Link to Zoom recording of ${mostRecentPastEvent.title} Premiere`}
        key={Math.random()}
      >
        <div>
          <h2>{mostRecentPastEvent.title}</h2>
          <h3>
            <RedBoldText>{mostRecentPastEvent.numberOfSchools}</RedBoldText>{' '}
            Schools Participated with a total of{` `}
            <RedBoldText>{mostRecentPastEvent.numberOfFilms}</RedBoldText>{' '}
            student short films.
          </h3>
          <SpecialButton>
            <Button
              theme="outline"
              actionType="external"
              link={headingOneButtonPath}
            >
              {headingOneButtonText}
            </Button>
          </SpecialButton>
          <ListInline>{schoolsList}</ListInline>
        </div>
      </ContentBlock>
    );
  }

  return (
    <>
      <HeroHeading sas>
        <Logo />
        <h1>{headingOne}</h1>
        <p style={{ fontWeight: 'normal' }}>{subheading}</p>
        {pageImageBlock?.asset?.gatsbyImageData ? (
          <ImageContainer
            image={pageImageBlock?.asset?.gatsbyImageData ?? null}
            type="dynamic"
            alt={pageImageBlock?.alt ? pageImageBlock.alt : ``}
          />
        ) : null}
      </HeroHeading>
      <LayoutContainer hasSectionGaps>
        <StyledSection>
          {currentYearBlock}
          <StyledSection>
            <ContentBlock
              heading={headingTwo}
              showImage={false}
              showCallToAction={false}
              callToActionLink="/sas-submission-guidelines"
              callToActionTitle="Submission Guidelines"
              callToActionType="internal"
            >
              <PortableText value={sasTextBlock} />
            </ContentBlock>
          </StyledSection>
          {previousYearBlock}
          {/* <h2 style={{ color: `red` }}>
              Join us for the live podcast of the Premiere of the 2023 Student
              Animation Showcase.
            </h2>
            <h2 style={{ color: `red` }}>
              Tune in on <u>June 30th at 7 pm PT</u> on the Zoom link below
            </h2> */}
        </StyledSection>
        {/* <StyledSection> */}
        {/* {hasGallery ? <Gallery images={galleryManyBlock} /> : null} */}
        {/* </StyledSection> */}
      </LayoutContainer>
    </>
  );
};

export default StudentAnimationShowcasePage;

// a helper function Head used to generate SEO
export function Head({ data }: HeadProps<SASPageQuery>) {
  const { name } = data.sasPage;
  return <SEO title={name}></SEO>;
}

export const query = graphql`
  query SasPageQuery {
    sasPage: sanityPage(name: { eq: "Student Animation Showcase" }) {
      name
      headingOne
      subheading
      headingOneButtonPath
      headingOneButtonText
      headingTwo
      pageImageBlock {
        alt
        asset {
          id
          gatsbyImageData
        }
        hotspot {
          y
          x
          width
          height
        }
        crop {
          top
          right
          left
          bottom
        }
      }
      hasGallery
      sasTextBlock: _rawContent
      sasPremierBlocks {
        title
        schools
        numberOfFilms
        numberOfSchools
        premierDate
        premierTime
        timezone
        submissionFormGoLiveDate
        formSubmissionContent: _rawFormSubmissionContent
        deadlineSubmissionFormDate
        premierZoomLink
        premierRecordedEventLink
      }
      galleryManyBlock {
        alt
        asset {
          id
          gatsbyImageData
        }
        crop {
          top
          right
          left
          bottom
        }
        hotspot {
          y
          x
          width
          height
        }
      }
    }
  }
`;
