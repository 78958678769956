// npm
import React from 'react';
// components
import CardContent from '../CardContent/CardContent';
import BookCardContent from '../BookCardContent/BookCardContent';

// images
import DefaultThumbnailImage from '@components/atoms/images/DefaultThumbnailImage';

// styles
import {
  StyledCardAnchorContainer,
  StyledImageContainer,
  StyledTitleContainer,
  StyledTitle,
  StyledCardLinkContainer,
} from './styles';

// types
import type { MediumCardProps } from './types';

function MediumCard({
  cardType,
  actionType,
  to = `/`,
  href = `#`,
  tagText,
  title,
  author,
  isbn,
  publisher,
  dateOfPublication,
  category,
  description,
  image,
}: MediumCardProps) {
  // console.log(isbn);
  switch (cardType) {
    case `home`:
      return actionType === `anchor` || actionType === `external` ? (
        <StyledCardAnchorContainer
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledImageContainer>
            {image || <DefaultThumbnailImage height={134} width={282} />}
          </StyledImageContainer>
          <CardContent
            actionType="anchor"
            cardType={cardType}
            tagText={tagText}
            title={title}
            description={description}
            charLimitTitle={40}
            charLimitDesc={82}
          />
        </StyledCardAnchorContainer>
      ) : (
        <StyledCardLinkContainer to={to}>
          <StyledImageContainer>
            {image || <DefaultThumbnailImage height={134} width={282} />}
          </StyledImageContainer>
          <CardContent
            actionType="link"
            tagText={tagText}
            title={title}
            description={description}
            charLimitTitle={40}
            charLimitDesc={82}
          />
        </StyledCardLinkContainer>
      );
    case `resource`:
      return (
        <StyledCardAnchorContainer
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledImageContainer>
            {image || <DefaultThumbnailImage height={134} width={282} />}
          </StyledImageContainer>
          <CardContent
            actionType="anchor"
            cardType={cardType}
            title={title}
            description={description}
            charLimitTitle={40}
            charLimitDesc={82}
          />
        </StyledCardAnchorContainer>
      );
    case `books`:
      return (
        <StyledCardLinkContainer
          style={{
            minHeight: `fit-content`,
            width: `100%`,
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <StyledTitleContainer
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center', // Aligns items vertically
              justifyContent: 'flex-start', // Aligns items horizontally to the start,
              flexWrap: 'wrap',
              paddingLeft: '10px',
              // padding: '7px', // Add padding if needed
            }}
          >
            {' '}
            <StyledTitle>{title}</StyledTitle>
          </StyledTitleContainer>
          <BookCardContent
            // actionType="link"
            cardType={cardType}
            // tagText={genre}
            // title={title}
            author={author}
            isbn={isbn}
            publisher={publisher}
            dateOfPublication={dateOfPublication}
            category={category}
            // description={description}
            charLimitTitle={40}
            charLimitDesc={82}
          />
        </StyledCardLinkContainer>
      );
    case `pastEvent`:
    default:
      return (
        <StyledCardLinkContainer
          to={to}
          style={{
            minHeight: `fit-content`,
            width: `100%`,
          }}
        >
          <CardContent
            actionType="link"
            cardType={cardType}
            tagText={tagText}
            title={title}
            description={description}
            charLimitTitle={40}
            charLimitDesc={82}
          />
        </StyledCardLinkContainer>
      );
  }
}

export default MediumCard;
