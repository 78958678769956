import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: var(--size-dual-nudge);
  margin: 0 auto;
`;

export const activeCardStyles = css`
  background: var(--c-white);
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%), 0 16px 24px rgb(0 0 0 / 10%);
  transition: all 0.5s var(--animation-bezier);
`;

// interface StyledPostCardContainerProps {
//   $type?: string;
// }

export const StyledPostCardContainer = styled(Link)`
  background: var(--c-aef-white);
  border-radius: var(--radius-lg);
  display: flex;
  align-items: center;
  gap: var(--size-1);
  text-decoration: none;
  border: 1px solid var(--c-grey-1);
  padding: var(--size-1);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%), 0 0 1px rgb(0 0 0 / 0%);
  cursor: pointer;
  transition: all 0.5s var(--animation-bezier);
  flex-basis: calc(
    50% - var(--size-3dn)
  ); /* Make cards take half the width on desktop */
  &:hover,
  &:focus {
    background: var(--c-white);
    box-shadow: 0 2px 10px rgb(0 0 0 / 20%), 0 16px 24px rgb(0 0 0 / 10%);
  }
  &:hover svg,
  &:focus svg {
    color: var(--c-yellow-1);
  }
`;

interface StyledPostCardImgProps {
  image: IGatsbyImageData;
}

export const StyledPostCardImg = styled(GatsbyImage)<StyledPostCardImgProps>`
  border-radius: var(--radius-md);
  width: 200px;
  height: 134px;
  object-fit: contain; /* Maintain aspect ratio while fitting the image within the specified dimensions */
`;

interface StyledFontAwesomeIconProps {
  icon: IconProp;
}

export const StyledFontAwesomeIcon = styled(
  FontAwesomeIcon,
)<StyledFontAwesomeIconProps>`
  color: var(--c-grey-3);
  font-size: var(--size-4);
  transition: all 0.5s var(--animation-bezier);
`;
