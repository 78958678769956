import React from 'react';
import { GatsbyBrowser } from 'gatsby';
// import { config, library } from '@fortawesome/fontawesome-svg-core';
// import {
//   faFacebook,
//   faTwitter,
//   faYoutube,
//   faInstagram,
// } from '@fortawesome/free-brands-svg-icons';
// import { faShareAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Layout from './src/components/shared/Layout';

// Disable automatic CSS injection
// config.autoAddCss = false;

// Add the icons to the library
// library.add(faFacebook, faTwitter, faYoutube, faInstagram, faShareAlt, faTimes);

// if (typeof window !== `undefined`) {
//   // Preload the Font Awesome font files
//   const WebFont = require(`webfontloader`);

//   WebFont.load({
//     google: {
//       families: [`Roboto:400,500,700`], // Replace with the font used in your project
//     },
//     custom: {
//       families: [`FontAwesome`],
//       urls: [
//         `https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css`,
//       ], // Replace with the Font Awesome CSS URL
//     },
//     timeout: 2000, // Adjust the timeout as needed
//   });
// }

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Layout {...props} location={props.location}>
      {element}
    </Layout>
  );
};
