import styled from 'styled-components';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-2h);
  width: 100%;
`;
// export const StyledSectionTitle = styled.h2`
//   color: var(--c-dark-grey-3);
//   text-transform: capitalize;
// `;

export const SpecialButton = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center the contents */
  align-items: center;
  padding: 10px;

  a {
    color: var(--dark-grey-4);

    &:hover {
      // Define the styles for hover state
      color: var(--dark-grey-4);
    }
  }
`;
export const ListInline = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  display: inline;
  &:not(:last-child)::after {
    content: '•';
    margin-left: 0.5em; /* Adjust the space between dots */
    margin-right: 0.5em; /* Adjust the space between dots */
  }
`;

export const RedBoldText = styled.strong`
  color: #d04145;
  font-weight: bold;
`;
